import { AppState } from '../interfaces/types'
import { AppStateAction } from './AppContextTypes'

export const appStateReduces = (
    state: AppState,
    action: AppStateAction,
): AppState => {
    switch (action.type) {
        case 'setAdobeAnalytics':
            return {
                ...state,
                adobeAnalytics: action.payload,
            }
        case 'setConfiguration':
            return {
                ...state,
                configuration: action.payload,
            }
        case 'setError':
            return {
                ...state,
                error: action.payload,
            }
        case 'removeError':
            return {
                ...state,
                error: {
                    showError: false,
                    errorType: null,
                    errorDescription: '',
                    onButtonClick: () => {},
                },
            }
        case 'stopLoadingOnError':
            return {
                ...state,
                stopLoadingOnError: action.payload,
            }
        case 'setLoading':
            return {
                ...state,
                loading: action.payload,
            }
        case 'setShowLoading':
            return {
                ...state,
                showLoading: action.payload,
            }
        case 'setViewportSize':
            return {
                ...state,
                viewportSize: action.payload,
            }
        case 'setRouteSnapshot':
            return { ...state, routeSnapshot: action.payload }
        default:
            return state
    }
}
