import React from 'react'

type PropsIcon = {
    width?: number
    height?: number
    background?: string
}
export const WhatsappIcon = ({
    width = 16,
    height = 16,
    background = 'black',
}: PropsIcon) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 16 16"
        fill="#007ABC"
        xmlns="http://www.w3.org/2000/svg">
        <path
            d="M12.1786 3.76786C11.0804 2.64286 9.58036 2 7.97321 2C4.70536 2 2.02679 4.67857 2.02679 7.94643C2.02679 9.01786 2.32143 10.0357 2.83036 10.9196L2 14L5.13393 13.1964C6.01786 13.6518 6.98214 13.9196 7.97321 13.9196C11.2679 13.9196 14 11.2411 14 7.97321C14 6.36607 13.3036 4.89286 12.1786 3.76786ZM7.97321 12.9018C7.08929 12.9018 6.23214 12.6607 5.45536 12.2054L5.29464 12.0982L3.41964 12.6071L3.92857 10.7857L3.79464 10.5982C3.3125 9.79464 3.04464 8.88393 3.04464 7.94643C3.04464 5.24107 5.26786 3.01786 8 3.01786C9.3125 3.01786 10.5446 3.52679 11.4821 4.46429C12.4196 5.40179 12.9821 6.63393 12.9821 7.97321C12.9821 10.6786 10.7054 12.9018 7.97321 12.9018ZM10.7054 9.20536C10.5446 9.125 9.82143 8.77679 9.6875 8.72321C9.55357 8.66964 9.44643 8.64286 9.33929 8.80357C9.25893 8.9375 8.96429 9.28571 8.88393 9.39286C8.77679 9.47321 8.69643 9.5 8.5625 9.41964C7.67857 8.99107 7.11607 8.64286 6.52679 7.65179C6.36607 7.38393 6.6875 7.41071 6.95536 6.84821C7.00893 6.74107 6.98214 6.66071 6.95536 6.58036C6.92857 6.5 6.60714 5.77679 6.5 5.48214C6.36607 5.1875 6.25893 5.21429 6.15179 5.21429C6.07143 5.21429 5.96429 5.21429 5.88393 5.21429C5.77679 5.21429 5.61607 5.24107 5.48214 5.40179C5.34821 5.5625 4.97321 5.91071 4.97321 6.63393C4.97321 7.38393 5.48214 8.08036 5.5625 8.1875C5.64286 8.26786 6.60714 9.76786 8.10714 10.4107C9.04464 10.8393 9.41964 10.8661 9.90179 10.7857C10.1696 10.7589 10.7589 10.4375 10.8929 10.0893C11.0268 9.74107 11.0268 9.44643 10.9732 9.39286C10.9464 9.3125 10.8393 9.28571 10.7054 9.20536Z"
            fill={background}
        />
    </svg>
)
