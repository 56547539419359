import { useState, useEffect } from 'react'

import { DeviceName } from '@ds.e/foundation'
import { DeviceBreakpointOrienta } from '@constants/DeviceBreakpointOrienta'

const useDeviceSize = () => {
    const [device, setDevice] = useState<DeviceName | null>(null)

    useEffect(() => {
        const { innerWidth } = window
        let actualDevice: DeviceName = DeviceName.phone

        if (innerWidth <= DeviceBreakpointOrienta.phone)
            actualDevice = DeviceName.phone
        if (
            innerWidth > DeviceBreakpointOrienta.phone &&
            innerWidth < DeviceBreakpointOrienta.tablet
        )
            actualDevice = DeviceName.phone
        if (
            innerWidth > DeviceBreakpointOrienta.tablet &&
            innerWidth < DeviceBreakpointOrienta.laptop
        )
            actualDevice = DeviceName.laptop
        if (innerWidth > DeviceBreakpointOrienta.laptop)
            actualDevice = DeviceName.desktop

        setDevice(prevDevice =>
            prevDevice !== actualDevice ? actualDevice : prevDevice,
        )
    }, [])

    return device
}

export default useDeviceSize
