import React, { lazy, Suspense } from 'react'
import { Route, Routes } from 'react-router-dom'
import Loader from '@atoms/Loader'
import { PATHS } from '@constants/paths'

const PreOrientaPage = lazy(() => import('@pages/preOrienta/PreOrientaPage'))
const CustomerJourneyPage = lazy(
    () => import('@pages/customerJourney/CustomerJourneyPage'),
)
const TermsAndConditionsPage = lazy(
    () => import('@pages/termsAndConditions/TermsAndConditionsPage'),
)
// LOAD PAGE ASYNCHRONOUSLY
const HomePage = lazy(() => import('@pages/home/HomePage'))
const ErrorPage = lazy(() => import('@pages/error/ErrorPage'))
const AccessOrientaPage = lazy(
    () => import('@pages/accessOrienta/AccessOrientaPage'),
)

const renderLoader = () => <Loader message="Nos estamos preparando para ti" />

const AppRoutes = () => (
    <Suspense fallback={renderLoader()}>
        <Routes>
            {/* TODO: Evaluate for removal
            <Route
                path="*"
                element={<HomePage />}
            /> */}
            <Route
                path={PATHS.ROOT}
                element={<HomePage />}
            />
            <Route
                path={PATHS.HOME}
                element={<HomePage />}
            />
            <Route
                path={PATHS.ERROR}
                element={<ErrorPage />}
            />
            <Route
                path={PATHS.ACCESS_ORIENTA}
                element={<AccessOrientaPage />}
            />
            <Route
                path={PATHS.PRE_ORIENTA}
                element={<PreOrientaPage />}
            />
            <Route
                path={PATHS.TERMS_AND_CONDITIONS}
                element={<TermsAndConditionsPage />}
            />

            <Route
                path={PATHS.CUSTOMER_JOURNEY}
                element={<CustomerJourneyPage />}
            />
            {/* ADD PAGE ROUTE */}
        </Routes>
    </Suspense>
)

export default AppRoutes
