import React from 'react'
import { QueryClientProvider } from 'react-query'
import { BrowserRouter } from 'react-router-dom'
import ReactDOM from 'react-dom'
import { queryClient } from '@services/reactQuery/queryClient'
import { AppContextProvider } from '@storage/context/AppContextProvider'
import { AdobeContextProvider } from '@storage/context/AdobeContextProvider'
import App from './App'
import reportWebVitals from './reportWebVitals'

// const root = createRoot(document.getElementById('root') as HTMLElement)

ReactDOM.render(
    <React.StrictMode>
        <QueryClientProvider client={queryClient()}>
            <BrowserRouter>
                <AppContextProvider>
                    <AdobeContextProvider>
                        <App />
                    </AdobeContextProvider>
                </AppContextProvider>
            </BrowserRouter>
        </QueryClientProvider>
    </React.StrictMode>,
    document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// eslint-disable-next-line no-console
reportWebVitals(console.info)
