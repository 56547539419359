import React, { useEffect, useMemo } from 'react'
import { ReactQueryDevtools } from 'react-query/devtools'
import { useLocation } from 'react-router-dom'
import { useAppContext } from '@hooks/useAppContext'
import { useAdobeContext } from '@hooks/useAdobeContext'
import { useAdobeAnalytics } from '@hooks/useAdobeAnalytics'
import useDeviceSize from '@hooks/useDeviceSize'
import LocalStorage from '@storage/browser/LocalStorage'

import { getUserFontSize } from '@utilities/index'

import './scss/App.scss'
import FooterWrapper from './components/atomic/organisms/FooterWrapper'
import AppRoutes from './AppRoutes'

const App = () => {
    const location = useLocation()
    const device = useDeviceSize()
    const { setConfiguration, setViewportSize } = useAppContext()
    const { adobeState } = useAdobeContext()
    const { view: adobeView, error: adobeError, event: adobeEvent } = adobeState
    const { recorder, registerRouteSnapshot } = useAdobeAnalytics()

    const storage = new LocalStorage()

    useEffect(() => {
        if (adobeView) recorder()
        if (adobeError !== null || adobeEvent !== null) {
            recorder()
        }
    }, [adobeView, adobeError, adobeEvent])

    useEffect(() => {
        registerRouteSnapshot(
            location.pathname,
            adobeState.routeSnapshot.currentPage,
        )
    }, [location])

    useEffect(() => {
        if (device) {
            setViewportSize(device)
        }
    }, [device])

    useEffect(() => {
        const defaultUserFontSize = storage.get('userFontSize')
        if (defaultUserFontSize) {
            const htmlTag = document.documentElement
            htmlTag.style.fontSize = getUserFontSize(defaultUserFontSize)
        }

        setConfiguration(window.config)

        if (navigator.userAgent.includes('Mac')) {
            document.body.classList.add('os-mac')
        }
    }, [])

    const Footer = useMemo(() => <FooterWrapper />, [])

    return (
        <div className="APP_NAME">
            <main>
                <AppRoutes />
            </main>
            <footer>{Footer}</footer>
            <ReactQueryDevtools />
        </div>
    )
}

export default App
