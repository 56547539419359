export type Path =
    | '/home'
    | '/'
    | '/especialidades'
    | '/error'
    | '/miagenda'
    | '/agendar'
    | '/agendarconsulta'
    | '/provisory-pip'
    | '/especialidades/:specialtyId'
    | '/access'
    | '/access-identified'
    | '/customer_journey'
    | 'terminos-y-condiciones'
// PAGE PATHS TYPE DECLARATIONS

export const PATHS: Record<string, Path> = {
    HOME: '/home',
    ROOT: '/',
    SPECIALTIES: '/especialidades',
    ERROR: '/error',
    BOOKING: '/agendarconsulta',
    PROVISORY_PIP: '/provisory-pip',
    APPOINTMENT_SCHEDULER: '/agendar',
    SPECIALTY_DETAIL: '/especialidades/:specialtyId',
    MYDIARY: '/miagenda',
    ACCESS_ORIENTA: '/access',
    PRE_ORIENTA: '/access-identified',
    CUSTOMER_JOURNEY: '/customer_journey',
    TERMS_AND_CONDITIONS: 'terminos-y-condiciones',
    // PAGE PATHS DECLARATIONS
}
